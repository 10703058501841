<template>
  <v-row>
    <v-col>
      <Summary/>
      <v-row>
        <v-col cols="6">
          <AgentPerformance/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data(){
    return{}
  },
  components: {
    Summary: () => import("@/components/dashboard/Summary.vue"),
    AgentPerformance: () => import("@/components/dashboard/AgentPerformance.vue"),
  }
}
</script>